import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '@common/Layout';
import { Container } from '@components/global';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ConfettiGun from '@sections/ConfettiGun';

const ThanksPage = () => (
  <StaticQuery
    query={graphql`
      query {
        art_pot: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "customers_pot" }
        ) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Layout>
          <ConfettiGun />
          <Container>
            <Grid>
              <h1>Thank you</h1>
              <Art>
                <Img
                  fluid={data.art_pot.childImageSharp.fluid}
                  style={{ width: 480, maxWidth: '100%', marginBottom: -16 }}
                />
              </Art>
              <Link to="/">Go back to the homepage</Link>
            </Grid>
          </Container>
        </Layout>
      </React.Fragment>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  h1 {
    text-allign: center;
  }

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 1fr 1fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: center;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 48px;
  width: 350px;
`;

export default ThanksPage;
