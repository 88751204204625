import React from 'react';
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';

const ConfettiGun = () => {
  const { width, height } = useWindowSize();

  return (
    <Confetti
      colors={[
        'BlanchedAlmond',
        'LightCoral',
        'LavenderBlush',
        'LightCyan',
        'Azure',
      ]}
      numberOfPieces={300}
      width={1200}
      height={1200}
    />
  );
};

export default ConfettiGun;
